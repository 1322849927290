




























































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import SenderViewModel from '../viewmodels/sender'
// import { winnerListStore } from '../stores/add-winner-list-store'

@Observer
@Component({
  components: {
    Stepper: () => import('../components/stepper.vue')
  }
})
export default class SenderConfirm extends Vue {
  @Inject() vm!: SenderViewModel
  wallet = walletStore
  async sending() {
    this.vm.setCurrentStep(3)
    if (this.wallet.chainType === 'sol') {
      await this.vm.handleSolanaSend()
    } else {
      await this.vm.handleSending()
    }
  }
}
